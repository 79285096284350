import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Video from './section-components/video';
import FooterV2 from './global-components/footer-v2';
import Skill from './section-components/skill';
import Marketing from './section-components/marketing';

const About = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="About" />
        <Video customclass="pd-top-120 bg-none" />
        <Skill />
        <Marketing customclass="pd-top-120"/>
        <FooterV2 />
    </div>
}

export default About

