import React, { Component } from 'react';

import ContactUs from './ContactUs';

class Contact_Form extends Component {

    render() {
        let anchor = '#'
        let imgalt = 'image'
        let publicUrl = process.env.PUBLIC_URL+'/'
   
    return <div>
            <div className="contact-form-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center w-100">
                                <h2 className="title">Send  <span>your request </span></h2>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5">
                        <img src={publicUrl+'assets/img/others/21.png'} alt={imgalt}/>
                        </div>
                        <div className="col-lg-7 offset-xl-1">
                            <ContactUs/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     }
}

export default Contact_Form

