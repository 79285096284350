import React from 'react';
import CommingSoonPage from './section-components/comming-soon';
import Navbar from './global-components/navbar';

const CommingSoon = () => {
    return <div>
        <Navbar />
        <CommingSoonPage />
    </div>
}

export default CommingSoon

