import React from 'react';
import Navbar from './global-components/navbar';
import SliderV3 from './section-components/slider-v3';
import ServiceV4 from './section-components/services-v4';
import About from './section-components/about';
import Helpv2 from './section-components/help-v2';
import FooterV2 from './global-components/footer-v2';
import SoftwareStore from './section-components/software-store';
import Soft_Box_Tab from './section-components/soft-box-tab';

const Home_v6 = () => {
    return <div>
        <Navbar />
        <SliderV3 />
        <ServiceV4 />
        <Soft_Box_Tab />
        <About />
        <Helpv2 />
        <SoftwareStore/>
        <FooterV2 />
    </div>
}

export default Home_v6

