import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class Slider_V3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.slider_v3

    return <div>
                <div className="header-area sbtl-header-area" style={{flex:1, alignItems:'center',alignContent:'center',backgroundImage: 'url('+publicUrl+'assets/img/banner/94.jpg' , backgroundSize:'contain' ,opacity:0.9}}>
                  <div className="container">
                    <div className="banner-slider banner-slider-one">
                    { data.items.map( ( item, i )=>
                        <div key={ i } className="banner-slider-item">
                            <div className="row" >
                              <div className="col-xl-6 col-lg-1 col-md-6 offset-xl-1" style={{alignContent:'center', alignItems:'center'}}>
                                <div className="header-inner-details" style={{flex: 1, alignItems:'center',alignContent:'center'}}>
                                  <div className="header-inner" style={{border: '1px solid', backgroundColor: 'black', opacity:0.9}}>
                                   
                                    <h1 className="title s-animate-2"> { item.title2 }<span>{ item.title_color }</span></h1>
                                    <h3 className="sub-title s-animate-1" style={{color : "white"}}>{ item.title1 }</h3>
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 offset-lg-1 col-md-6 hidden-sm">
                                <div className="banner-thumb-wrap">
                                  <div className="banner-thumb">
                                  <p className="s-animate-3">{item.description}</p>
                                  <div className="btn-wrapper desktop-left padding-top-20">
                                      <text className="btn btn-red s-animate-4" href="#">{item.btntxt}</text>
                                    </div>
                                    {/* <img className="header-inner-img" src={ publicUrl+item.image_url } style={{ height:'100%',width:'100%' }}alt={ imgattr } /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                     ) }
                     
                    </div>
                  </div>
                </div>
          </div>
        }
}

export default Slider_V3