import React from "react";
import emailjs from 'emailjs-com';
export default function ContactUs(){

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('phD', 'template_n7519dr', e.target, 'user_bCExYaNieJ6rqqUFs9cWb')
          .then((result) => {
              console.log(result.text);
              alert('your request has been sent successfully');
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          

        }
return (
 <form className="riyaqas-form-wrap mt-5 mt-lg-0" onSubmit={sendEmail}>
        <div className="row custom-gutters-16">
            <div className="col-md-6">
                <div className="single-input-wrap">
                    <input type="text" className="single-input" name="name"/>
                    <label>Name</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="single-input-wrap">
                    <input type="text" className="single-input" name="E-mail"/>
                    <label>E-mail</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="single-input-wrap">
                    <input type="text" className="single-input" name="Subject"/>
                    <label>Subject</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="single-input-wrap">
                    <input className="single-input textarea" cols="20" name="Message"></input>
                    <label>Message</label>
                </div>
            </div>
            <div className="col-12">
                <input type="submit" className="btn btn-red mt-0" value="envoyer" href='/contact'/>
            </div> 
        </div>
    </form>

    );
    }
