import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';


class Contact_Info extends Component {
    render() {
        return <div>
        <div className="map-area pd-top-120">
            <div className="container">
                <div className="map-area-wrap">
                    <div className="row no-gutters">
                        <div className="col-lg-8">
                        <iframe title="ph-map" width="1075" height="440" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d409969.30139629636!2d10.082480703862114!3d36.605074634426416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd4aaba8c4bedd%3A0xaf3ef46b38d8f037!2sPH%20Diagnostics!5e0!3m2!1sfr!2stn!4v1641203799577!5m2!1sfr!2stn" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        
                        </div>  
                        <div className="col-lg-4 desktop-center-item">
                            <div>
                                <div className="contact-info">
                                    <h4 className="title">{sectiondata.contactinfo.title}</h4>
                                    <p className="sub-title">{sectiondata.contactinfo.description}</p>
                                    <p><span>Address:</span> {parse( sectiondata.contactinfo.address )}</p>
                                    <p><span>Mobile:</span> {sectiondata.contactinfo.mobile}</p>
                                    <p><span>E-mail:</span> {sectiondata.contactinfo.mail}</p>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        </div>
    }
}

export default Contact_Info