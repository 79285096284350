import React, { Component } from "react";
import sectiondata from "../../data/sections.json";

class WordProcessing extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "image";
    let data = sectiondata.whatwedo;
    let customclass = this.props.customclass ? this.props.customclass : "";

    return (
      <div>
        <div className={"work-processing-area " + customclass}>
          <div className="container">
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" style={{top:0}}>
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/11.jpg"}
                    alt="img"
                  />
                </div>
              </div>
              <div
                className="col-xl-4 col-md-6 order-lg-1 desktop-center-item"
               
              >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h1 className="title">BABIRUS</h1>
                    <p>
                      Babirus are committed to working with healthcare 
                      across the MENA region to deliver innovative and
                      technologically advanced diagnostic solutions that help to
                      improve people’s health. <br /> Headquartered in Dubai,
                      UAE , the company is focused on marketing a portfolio of
                      leading global healthcare brands and best-in-class
                      diagnostic products in three key areas: Molecular
                      diagnostics, personalised medicine, and immunodiagnostics.{" "}
          
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-4 col-md-6" style={{top:10, left:-20}} >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/22.png"}
                    alt="img"
                    href=""
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>ORGENTEC</span>
                    </h2>
                    <p>
                      Alegria® – for Automated Laboratory Diagnostics Our fully
                      automatic analytical instrument Alegria® can be used for
                      both product lines from ORGENTEC Diagnostika, as well as
                      for autoimmune diagnostics and infection disease serology.
                      <br />
                      Alegria® is not limited to specific sample numbers. Even
                      individual samples can be rapidly and economically
                      analyzed with this instrument – or up to 30 samples can be
                      processed in a single run, in any combination.
                      <br /> This makes Alegria® especially attractive for
                      laboratories with average or lower sample frequency or for
                      acute diagnostics. Each individual test strip contains a
                      complete set of reagents for determination of a single
                      sample. Any sample preparation or complex set-up of the
                      instrument for operation are unnecessary. The individual
                      barcode of the test strip includes all necessary
                      information about the test, analysis and evaluation, and
                      the expiration date of the test strip.
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" >
                <div className="thumb swp-right-thumb" >
                  <img
                    src={publicUrl + "assets/img/partners/3.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">AMA</h2>
                    <p>
                      AMA was established by a group of people with experience
                      in the medical and chemical fields. AMA is focused on
                      developing and producing of innovative test-systems for
                      medical diagnostics and for scientific goals. AMA Co. Ltd
                      since the beginning was actively involved in a field of
                      gastroenterology, producing the test-systems for
                      diagnostics of the stomach infection H.pylori. Global
                      prevalence of gastritis, ulcer and gastric cancer, which
                      are related to H.pylori, and the urgent need in cheap,
                      express, easy-to-use and precise diagnostics tools were
                      the reasons AMA's specialists had developed new products:
                      AMA® Rapid Urease Test (AMA® RUT Expert) and HELIC Ammonia
                      Breath Test (HELIC® ABT).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6" >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/4.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>NOVA</span> BIOMEDICAL
                    </h2>
                    <p>
                      Le Stat Profile Prime Plus est un analyseur complet de
                      sang total pour soins intensifs qui réunit gaz du sang,
                      électrolytes, métabolites, co-oxymétrie et permettant de
                      rendre 32 résultats calculés dans appareil simple et
                      compact. Le Prime Plus associe les nouvelles technologies
                      sans maintenance des cartouches remplaçables pour les
                      biocapteurs, les réactifs et le CQ liquide, ainsi qu’une
                      nouvelle technologie de co-oxymétrie brevetée, sans
                      entretien et sans hémolyse Les résultats de Prime Plus
                      sont rendus très rapidement – un panel complet chaque
                      minute – et sont associés avec une connectivité
                      bidirectionnelle et un système de gestion des données
                      interne puissant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12">
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/5.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">CHISON</h2>
                    <p>
                      CHISON Medical Technologies Co., LTD(Stock Code: 688358)
                      has emerged as World’s leading Diagnostic Ultrasound
                      manufacturer since its inception in 1996. In the journey
                      of constant development and revolution in technologies,
                      CHISON has set its foot independently with it’s own
                      Research and Development Centres in China and USA. CHISON
                      is globally accepted and appreciated for its evolving
                      culture. It has been approved as one of the most Hi-Tech
                      Enterprise by Jiangsu Technology Department. CHISON owns
                      about 5 R&D centres in China and US, among them, the R&D
                      headquarter has been awarded as National Competent
                      Enterprise on Intellectual Property Rights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/22.jpg"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item" >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>BESDATA</span>
                    </h2>
                    <p>
                      BESDATA Airway Management Ecosystem comes with
                      multi-functional sets including Flexible Endoscope, Rigid
                      Stylet, Video Laryngoscope, Video Laryngeal Mask, Video
                      double lumen endobronchial tube & Video Endotracheal tube
                      All-In-One System. It combines The smart anti-fog white
                      LED light and CMOS chip-on-tip technology together. The
                      multiple solutions and cost affordable sets helping the
                      doctors visualized airway management.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12">
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/7.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">GIMA</h2>
                    <p>
                      Le catalogue GIMA vous propose plus de 8500 références de
                      produits disponibles et livrables rapidement, parmi
                      lesquels vous trouverez notamment: des thermomètres, des
                      tests diagnostiques, des glucomètres, des consommables à
                      usage médical, des tabliers et sabots, des sacs, des
                      balances, des adipomètres, des fauteuils roulants, des
                      produits pour le confort des patients, des chariots, des
                      lits, du mobilier, des aspirateurs, des nébuliseurs, des
                      dopplers, des cardiotocographes, des instruments
                      gynécologiques jetables, des unités électrochirurgicales,
                      des spéculums, des lampes, des stéthoscopes, des
                      sphygmomanomètres, ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/33.jpg"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>BORSAM</span>
                    </h2>
                    <p>
                      Borsam Biomedical Instruments Co.,ltd., located in
                      University Innovation Park, Nanshan District, Shenzhen, is
                      a professional enterprises in one of the science and
                      technology committed to HOLTER (dynamic ECG) R & D,
                      production and sales, access to a number of products
                      patent. In 2014 was named the national high-tech
                      enterprises. We believe that all products and technologies
                      must be in clinical practice, we always focus on the
                      latest clinical information, provide the most advanced
                      technical means to find more hidden diagnostic
                      information. We work with the needy hospitals and research
                      institutions to provide them with technical, means to
                      achieve their research topics.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center custom-gutters-16 single-work-processing" >
              <div className="col-xl-5 col-md-6 order-lg-12">
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/44.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">OPTIMEDICAL</h2>
                    <p>
                      OPTI Medical Systems, Inc. specializes in the design and
                      manufacturing of point-of-care and laboratory diagnostic
                      solutions. OPTI Medical Systems leverages advanced
                      technologies to develop reliable, cost-effective products
                      that combine accuracy with convenience, ease of use, and
                      clinical flexibility to support infectious disease
                      diagnostics as well as point-of-care blood gas and
                      electrolyte analysis using optical fluorescence
                      technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/55.jpg"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item" >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>DIPROMED</span>
                    </h2>
                    <p>
                      Dipromed is an Italian company that has been operating in
                      the medical device sector since 1978. <br />
                      On this site we present our company, our products and
                      provide constant updates with our news. In fact, we
                      believe that research is a pivotal point, in particular as
                      regards medicine and its applications.
                      <br />
                      For this reason our company collaborates with Italian and
                      international universities and research centers; hospital
                      centers and innovation centers. All this to have
                      increasingly innovative and functional products both in
                      the biomedical sector and in that of polymeric materials.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" >
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/11.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">WONDFO</h2>
                    <p>
                      Guangzhou Wondfo Biotech Co., Ltd., headquartered in
                      Guangzhou science city, has been focusing on the R&D,
                      production, sales and service of point-of-care testing
                      (POCT) products and providing customers with professional
                      rapid diagnosis and chronic disease management solutions
                      since founded in 1992.
                      <br />
                      Wondfo now has more than 3000 employees and a wide range
                      of products for the rapid identification of cardiovascular
                      diseases, inflammation, tumor, infectious diseases, drug
                      abuse, pregnancy, and so on, widely sold to 140+ countries
                      and regions.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6" >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/12.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>PARTACUS</span> BIOMED
                    </h2>
                    <p>
                      Spartacus-Biomed is providing novel Biomarkers that will
                      equip physicians with tools to improve the early
                      diagnosis, to identify patients at risk of developing
                      diseases, and to characterize patient subsets with a
                      greater likelihood of response to preventive therapies.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" >
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/13.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">ESFEQA</h2>
                    <p>
                      The European Society for external quality assessment
                      (ESfEQA) offers a variety of external quality tests to
                      medical laboratories in the areas of biochemistry,
                      hematology, immunology and microbiology.
                      <br />
                      Monthly and quarterly programs from ESfEQA are available
                      to the users and managers responsible for quality
                      standards in medical laboratories. The ESfEQA quality
                      assessment samples and the ESfEQA service are
                      characterised by a high quality, reliability, simplicity
                      of use and flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6" >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/66.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item" >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>SILFRADENT</span>
                    </h2>
                    <p>
                      Today Silfradent offers a complete range of products in
                      the field of the dental laboratory, which includes
                      appreciated and resistant micromotors, maximum precision
                      isoparallelometers, sandblasters, model trimmers,
                      hydraulic presses, polishing and drill machines,
                      vibrators, powerful steam generators and reliable vacuum
                      mixers.
                      <br />
                      In the medical line, Silfradent manufactures high
                      precision surgery and implantology devices, a varied and
                      wide range of inserts, latest generation surgical lights
                      and an innovative system in bone-tissue regeneration
                      through growth factors (CGF Concentrated Growth Factors).
                      All the equipment is designed to obtain high-level
                      performance and user-friendliness as well as to ensure the
                      maximum safety for both the operator and the patient.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" >
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/15.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item" >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">SOLGENT</h2>
                    <p>
                      The paradigm of modern medicine has changed from therapy
                      to precision medicine for prevention and prediction.
                      <br />
                      Solgent is providing products and services that allow
                      molecular diagnostic test to check if infected or not.
                      <br />
                      Based on the technology that we have developed and
                      produced from various research reagents to molecular
                      diagnostic kits, we will do our best to become a Global
                      Leader in the molecular diagnostic market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6">
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/16.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>3DMED</span>
                    </h2>
                    <p>
                      3D Medicines (3DMed), founded in 2010, is a leader in
                      precision medicine in China dedicated to making early and
                      personalized diagnosis accessible to each patient.
                      <br />
                      3DMed set the standard of innovation in automated
                      molecular diagnostics and artificial intelligence (AI) to
                      help physicians leverage genetic information to tailor
                      treatment to individual patients.
                      <br />
                      3DMed clinical laboratory is accredited by College of
                      American Pathologists (CAP) and certified by Clinical
                      Laboratory Improvement Amendments (CLIA) to provide
                      clinical testing in various disease areas. CAP CLIA About
                      3DMed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing">
              <div className="col-xl-5 col-md-6 order-lg-12" >
                <div className="thumb swp-right-thumb">
                  <img
                    src={publicUrl + "assets/img/partners/77.jpg"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 order-lg-1 desktop-center-item" >
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">PANAGENE</h2>
                    <p>
                      PANAGENE have been supplying Peptide Nucleic Acid (PNA)
                      worldwide and exclusively since 2006 based on our unique
                      invention of efficient synthesis of PNA with high purity,
                      and nowadays we are possessing the best knowledge on PNA
                      and the largest number of patents on the PNA and its
                      applications in the world.
                      <br />
                      PNA, an artificial genetic material (a DNA mimic) invented
                      at early 1990s, has been drawn much attention of
                      researchers as a promising material candidate for
                      especially diagnostic and therapeutic use, due to its
                      advanced physicochemical properties such as strong binding
                      affinity with complementary nucleic acids and superior
                      biological/chemical stability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center custom-gutters-16 single-work-processing" >
              <div className="col-xl-6 col-md-6" >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/partners/88.png"}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-md-6 desktop-center-item">
                <div className="work-processing-details">
                  <div className="section-title style-four">
                    <h2 className="title">
                      <span>CNS</span>
                    </h2>
                    <p>
                      Cambridge Nutritional Sciences offers premium quality
                      diagnostic tests, specifically focused on IgG-mediated
                      food intolerance and adrenal function.
                      <br />
                      The extensive range of FoodPrint panels enables the
                      measurement of IgG antibodies to a wide variety of foods,
                      thus facillitating the identification of elevated immune
                      responses to any that are included on the chosen panel.
                      This can subsequently help to guide appropriate dietary
                      adjustments to address IgG-mediated food intolerance.
                      <br />
                      The CNS Adrenal Function Profile is an effective
                      diagnostic tool used to assess the functioning of the
                      adrenals. These glands are instrumental to the body’s
                      response to stress, in addition to impacting the immune
                      system, metabolism and blood pressure. <br />
                      Using the appropriate tests, can provide individuals with
                      an insight into the functionality of vital systems within
                      the body. This knowledge can help to guide specific
                      nutritional and lifestyle modifications aimed at
                      maintaining, or restoring optimal health and wellbeing.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WordProcessing;
